import React from 'react';
import PropTypes from 'prop-types';
import RcSlider from 'rc-slider/lib/Slider';
import 'rc-slider/assets/index.css';
import './Slider.scss';

const Slider = ({ values, selectedValue, onChange, isDisabled }) => {
  const EXTRA_PADDING_FOR_INCREASE_TOUCH_AREA = 7;
  const WHITE_THEME_TEXT_COLOR = 'rgba(0,0,0,0.7)';

  const AMOBEE_BLUE = '#009EBD';
  const labelsStyle = {
    color: WHITE_THEME_TEXT_COLOR,
    marginTop: '4px',
    padding: EXTRA_PADDING_FOR_INCREASE_TOUCH_AREA + 'px',
    top: -EXTRA_PADDING_FOR_INCREASE_TOUCH_AREA + 'px',
  };
  const labels = {};
  values.forEach((value, i) => (labels[i] = { label: value['label'], style: labelsStyle }));
  // 'selectedValueIndex' is converting value from 1-based to 0-based indexing, which is used by RcSlider component.
  const selectedValueIndex = selectedValue['value'] - 1;

  return (
    <div className="slider-component">
      <RcSlider
        onChange={(value) => value != selectedValueIndex && onChange(values[value])}
        value={selectedValueIndex}
        railStyle={{ height: 3 }}
        trackStyle={{ backgroundColor: AMOBEE_BLUE, height: 3 }}
        handleStyle={{
          borderColor: AMOBEE_BLUE,
          backgroundColor: AMOBEE_BLUE,
          height: 16,
          width: 16,
          boxShadow: 'none',
          cursor: 'pointer',
        }}
        marks={labels}
        min={0}
        max={values.length - 1}
        dotStyle={{ backgroundColor: 'transparent', borderColor: 'transparent', height: 0, width: 0 }}
        disabled={isDisabled}
      />
    </div>
  );
};

Slider.propTypes = {
  values: PropTypes.array.isRequired,
  selectedValue: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

Slider.defaultProps = {
  isDisabled: false,
};

export default Slider;
