import {react2angular} from 'react2angular';
import common from 'infra/utils/common';
import {validateUserAuthentication} from '../../data/audience-linkedin-helper';
import LifestyleModal from 'react/components/apps/audience/AudienceBuilder/Modals/LifestyleModal/LifestyleModal';
import FirstPartyModal from 'react/components/apps/audience/AudienceBuilder/Modals/FirstPartyModal/FirstPartyModal';
import CommercialsModal from 'react/components/apps/audience/AudienceBuilder/Modals/CommercialsModal/CommercialsModal';
import TvShowsModal from 'react/components/apps/audience/AudienceBuilder/Modals/TvShowsModal/TvShowsModal';
import AdvancedTvShowsModal from 'react/components/apps/audience/AudienceBuilder/Modals/AdvancedTvShowsModal/AdvancedTvShowsModal';
import LinkedinDemographicsModal
    from 'react/components/apps/audience/AudienceBuilder/Modals/LinkedinDemographicsModal/LinkedinDemographicsModal';
import LinkedinIndustriesModal from 'react/components/apps/audience/AudienceBuilder/Modals/LinkedinIndustriesModal/LinkedinIndustriesModal';
import DemographicsModal from 'react/components/apps/audience/AudienceBuilder/Modals/DemographicsModal/DemographicsModal';
import {checkNameSensitivity, checkSensitivity} from 'react/utils/sensitivityUtils';
import ReviewSensitivityModal
    from 'react/components/apps/audience/AudienceBuilder/Modals/ReviewSensitivityModal/ReviewSensitivityModal';
import AudienceNameModal
    from 'react/components/apps/audience/AudienceBuilder/Modals/AudienceNameModal/AudienceNameModal';
import AudiencePreviewWidget
    from 'react/components/apps/audience/AudienceBuilder/AudiencePreviewWidget/AudiencePreviewWidget';
import SegmentsImageToggleButtonSelector
    from 'react/components/apps/audience/AudienceBuilder/SegmentsImageToggleButtonSelector/SegmentsImageToggleButtonSelector.js';
import AudienceTarget from 'react/components/apps/audience/AudienceBuilder/AudienceTarget/AudienceTarget';
import AudienceSecondaryBar
    from 'react/components/apps/audience/AudienceBuilder/AudienceSecondaryBar/AudienceSecondaryBar';
import CloneAudience from 'react/components/apps/audience/AudienceBuilder/CloneAudience/CloneAudience';
import add_tooltip from 'common/tooltip/tooltip.js';
import {
    getSegmentValuesSummary,
    hasActivateAudiencePermission,
    isActivateAudienceEnabled,
    SEGMENT_ROW_MAP,
    LOGICAL_OPERANDS,
    TV_SHOWS_SEGMENT_TYPES,
    COMMERCIALS_SEGMENT_TYPES,
    isDeterministicDynamicActivationAllowed,
    isSmartTvChannel,
    isAdvancedTvChannel,
    deterministicPermissionName,
    customPricePermissionName,
    optimizeSegmentPermissionName,
    CHANNELS,
    DEFAULT_MANDATORY_GEO, 
    COUNTRIES,
    showAudienceIpCountPermissionName,
} from '../../data/audience-segment-builder-helper';
import {
    createAlwaysOnAudience,
    createAmplifiedAudience,
    createAudienceTargetTaxonomy,
    createAudienceTargetUserList,
    createDeterministicAudience,
    createUserListForDeterministicAudience,
    getAdvancedTvGenresMetaData,
    getAmplifiedEstimatedReachGoal,
    getAudienceSize,
    getDataContract,
    getFirstPartyDataByProgram,
    getLinkedinMetaData,
    getSegmentIds,
    getSegmentInterestsData,
    getSegmentParams,
    getTaxonomyCategory,
    getTvCommercialsMetaDataV2,
    getTvGenresMetaData,
    getTvNetworksMetaData,
    getTvShowsMetaData,
    isAudienceSizeTooSmall,
} from '../../react/services/AudienceInsightsService';
import {QUERY_NAMES} from '../../react/services/AudienceServiceHelper';
import * as MixpanelAudience from '../../react/infra/mixpanel/MixpanelAudience';
import {PERMISSION} from "../../infra/utils/export";

const audienceBuilderModule = angular.module(__filename, [
    require("./modals/interests-modal").name,
    require("./modals/websites-modal").name,
    require("./modals/linkedin/linkedin-companies-modal").name,
    require("./modals/linkedin/linkedin-jobs-modal").name,
    require("./modals/load-audience/load-audience-modal").name,
    require("../../widgets/audience-preview-widget/audience-preview-widget").name,
    require('common/modals/confirmation/confirm-action.modal.service').name,
    require("data/audience-mgmt").name,
    require("./modals/apps-modal").name
]);

audienceBuilderModule
    .component('reactAudiencePreviewWidget', react2angular(AudiencePreviewWidget, [
        'activatedAdvertiser',
        'activatedAmplifiedThreshold',
        'activatedAlwaysOnThreshold',
        'activateAudienceDisabledText',
        'activatedMarket',
        'activatedDataContractId',
        'activatedDataContractText',
        'activatedCategoryId',
        'audienceDistributionService',
        'audienceId',
        'audienceName',
        'audienceSegment',
        'channel',
        'createAmplifiedAudience',
        'createAlwaysOnAudience',
        'createAudienceTargetTaxonomy',
        'createAudienceTargetUserList',
        'createDeterministicAudience',
        'createUserListForDeterministicAudience',
        'dspService',
        'getAmplifiedEstimatedReachGoal',
        'getDataContract',
        'getSegmentIds',
        'getTaxonomyCategory',
        'handleActivatingAudience',
        'handleActivatingTarget',
        'hasAmplificationModeSelector',
        'householdDistributionService',
        'isActivateAudienceEnabled',
        'isActivateAudienceVisible',
        'isActivateEnabled',
        'isActivateTargetEnabled',
        'isActivateVisible',
        'isAudienceAmplifiedActivated',
        'isAudienceAlwaysOnActivated',
        'isAudienceDeterministicActivated',
        'isDeterministicDynamicAllowed',
        'isRequestCancelledService',
        'isTargetModalProcessing',
        'isContextualUniquenessError',
        'marketsAndAdvertisersPromise',
        'notificator',
        'onAudienceDataLoaded',
        'onExploreAudience',
        'onTargetAudienceSubmit',
        'ssoService',
        'trackTooNarrow',
        'updateAudienceAmplifiedActivated',
        'updateAudienceAlwaysOnActivated',
        'updateAudienceDeterministicActivated',
        'userId',
        'allowDeterministicSegment',
        'allowCustomPriceSegment',
        'allowOptimizeSegment',
        'hideAudiencePreview',
        'showAudienceIpCount'
    ]))
    .component('lifestyleModal', react2angular(LifestyleModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'lifestyleInput', 'lifestyles']))
    .component('firstPartyModal', react2angular(FirstPartyModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'firstPartyInput', 'firstPartyPromise']))
    .component('segmentsImageToggleButtonSelector', react2angular(SegmentsImageToggleButtonSelector, ['segmentsData', 'selectedSegment', 'onToggle']))
    .component('commercialsModal', react2angular(CommercialsModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'commercialsInput', 'commercialsMetadataPromise', 'channel', 'isTimeframeVisible', 'geo']))
    .component('tvShowsModal', react2angular(TvShowsModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'tvShowsInput', 'channel', 'tvNetworksMetadataPromise', 'tvGenresMetadataPromise', 'tvShowsMetadataPromise', 'channel', 'isTimeframeVisible']))
    .component('advancedTvShowsModal', react2angular(AdvancedTvShowsModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'tvShowsInput', 'tvGenresMetadataPromise', 'channel', 'geo']))
    .component('demographicsModal', react2angular(DemographicsModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'demographicsInput', 'allGeos', 'disableGeos', 'defaultGeo', 'isGeoOnly', 'channel', 'hasUsGeoPermission', 'hasSgTelcoEthnicityPermission', 'type']))
    .component('linkedinDemographicsModal', react2angular(LinkedinDemographicsModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'demographicsInput', 'disableGeos', 'linkedinMetaDataPromise']))
    .component('linkedinIndustriesModal', react2angular(LinkedinIndustriesModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'industriesInput', 'linkedinMetaDataPromise']))
    .component('reviewSensitivityModal', react2angular(ReviewSensitivityModal, ['isOpen', 'data', 'modalTitle', 'keepPhrasesText', 'removePhrasesText', 'onSubmit', 'onCancel', 'segmentMap']))
    .component('audienceNameModal', react2angular(AudienceNameModal, ['isOpen', 'origName', 'origErrorMessage', 'checkName', 'onSubmit', 'onCancel']))
    .component('audienceTarget', react2angular(AudienceTarget, ['marketsAndAdvertisersPromise', 'audienceId', 'audienceName',
        'audienceSegment', 'isActivateEnabled', 'isActivateAudienceEnabled', 'isActivateAudienceVisible', 'activateAudienceDisabledText',
        'isActivateTargetEnabled', 'isActivateVisible', 'channel', 'getSegmentIds', 'createAudienceTargetTaxonomy',
        'createAudienceTargetUserList', 'createAmplifiedAudience', 'createAlwaysOnAudience', 'notificator',
        'updateAudienceDeterministicActivated', 'dspService', 'isAudienceDeterministicActivated', 'activatedAmplifiedThreshold',
        'activatedAlwaysOnThreshold', 'isAudienceAmplifiedActivated', 'isAudienceAlwaysOnActivated', 'activatedMarket',
        'activatedAdvertiser', 'activatedDataContractId', 'activatedDataContractText', 'activatedCategoryId',
        'updateAudienceAmplifiedActivated', 'updateAudienceAlwaysOnActivated', 'ssoService', 'getAmplifiedEstimatedReachGoal',
        'createDeterministicAudience', 'createUserListForDeterministicAudience', 'handleActivatingAudience', 'handleActivatingTarget',
        'widgetName', 'hasAmplificationModeSelector', 'getDataContract', 'getTaxonomyCategory', 'isDeterministicDynamicAllowed',
        'allowDeterministicSegment', 'allowCustomPriceSegment', 'allowOptimizeSegment']))
    .component('audienceSecondaryBar', react2angular(AudienceSecondaryBar, ['hasNoSegments', 'disableLoadAudience', 'onNewAudienceClick', 'onLoadAudienceClick',
        'onSaveAudienceClick', 'isDropdownVisible', 'audienceChannels', 'selectedChannel', 'onChannelChange', 'isAudienceActivated', 'createClonedAudience',
        'isCloneAudienceVisible']))
    .component('cloneAudience', react2angular(CloneAudience, ['createClonedAudience', 'updateIsCloneAudienceOpen', 'isOpen', 'audienceId', 'audienceName', 'audienceSegment']));

audienceBuilderModule.stateConfig = [{
    name: "audience-builder",
    url: "/audience-builder",
    params: {tokenStatus: null, channel: null},
    template: require("./audience-builder.html"),
    display: "Audience",
    data: {
        permissions: ['audience']
    },
    context: {
        audience_segment: "GeneralMold"
    },
    controller: audienceBuilderController
}];

audienceBuilderController.$inject = ['$scope', 'context', 'audienceMgmt', 'filtersPartition', 'interestsModal',
    'websitesModal', 'loadAudienceModal', 'notificator', 'confirmAction', 'linkedinJobsModal', 'linkedinCompaniesModal',
    'abiPermissions', '$stateParams', '$state', 'errorMgmt', '$window', '$rootScope', 'appsModal', 'dspService',
    'ssoService', '$location', 'audienceTableStructure', 'TargetsCommon', '$timeout'];

const demographicsSegmentByType = (type) => ({value: type ? `${type}Demographics` : 'demographics', label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"});
const commercialsSegmentByType = (type) => ({value: type ? `${type}Commercials` : 'commercials' , label: "Commercials", image: "./images/pages/audience-builder/images/commercials_img.png"});
const disabledSegment = (segment) => ({...segment, isDisabled: true, tagLabel: 'Coming soon'});
const debugOnlySegment = (segment) => ({...segment, debugOnly: true});

const SEGMENT_ROW_TYPES = {
    DEMOGRAPHICS:  demographicsSegmentByType(),
    SMART_TV_DEMOGRAPHICS:  demographicsSegmentByType('smartTv'),
    LINKEDIN_DEMOGRAPHICS:  demographicsSegmentByType(CHANNELS.linkedin?.name),
    INTERESTS: {value: "interests", label: "Interests", image: "./images/pages/audience-builder/images/interests_img.png"},
    WEBSITES: {value: "websites", label: "Websites", image: "./images/pages/audience-builder/images/websites_img.png"},
    LIFESTYLE: {value: "lifestyle", label: "Lifestyles", image: "./images/pages/audience-builder/images/lifestyle_img.png"},
    FIRST_PARTY: {value: "1st party", label: "1st Party", image: "./images/pages/audience-builder/images/1st_party_img.jpg", permission: 'first party segments'},
    TV_SHOWS: {value: "tvShows", label: "TV Shows", image: "./images/pages/audience-builder/images/tv_shows_img.jpg", permission: 'tv'},
    ADVANCED_TV_SHOWS: {value: "advancedTvShows", label: "TV Shows", image: "./images/pages/audience-builder/images/tv_shows_img.jpg", permission: 'tv'},
    APPS: {value: "apps", label: "Apps", image: "./images/pages/audience-builder/images/apps_img.png"},
    COMMERCIALS: commercialsSegmentByType(),
    SMART_TV_COMMERCIALS: commercialsSegmentByType('smartTv'),
    LINKEDIN_INDUSTRIES: {value: "linkedinIndustries", label: "Industries", image: "./images/pages/audience-builder/images/industries_img.jpg"},
    LINKEDIN_COMPANIES: {value: "linkedinCompanies", label: "Companies", image: "./images/pages/audience-builder/images/companies_img.jpg"},
    LINKEDIN_JOBS: {value: "linkedinJobs", label: "Jobs", image: "./images/pages/audience-builder/images/jobs_img.jpg"}
}

const WEB_SEGMENT_TYPES = [
    SEGMENT_ROW_TYPES.DEMOGRAPHICS,
    SEGMENT_ROW_TYPES.INTERESTS,
    SEGMENT_ROW_TYPES.WEBSITES,
    SEGMENT_ROW_TYPES.LIFESTYLE,
    SEGMENT_ROW_TYPES.FIRST_PARTY
];

const SG_TELCO_SEGMENT_TYPES = [
    SEGMENT_ROW_TYPES.DEMOGRAPHICS,
    SEGMENT_ROW_TYPES.INTERESTS,
    SEGMENT_ROW_TYPES.WEBSITES
];

const SMART_TV_SEGMENT_TYPES = [
    SEGMENT_ROW_TYPES.SMART_TV_DEMOGRAPHICS,
    SEGMENT_ROW_TYPES.INTERESTS,
    SEGMENT_ROW_TYPES.WEBSITES,
    SEGMENT_ROW_TYPES.LIFESTYLE,
    SEGMENT_ROW_TYPES.TV_SHOWS,
    SEGMENT_ROW_TYPES.SMART_TV_COMMERCIALS,
    SEGMENT_ROW_TYPES.FIRST_PARTY
];

const ADVANCED_TV_SEGMENT_TYPES = [
    SEGMENT_ROW_TYPES.SMART_TV_DEMOGRAPHICS,
    SEGMENT_ROW_TYPES.INTERESTS,
    SEGMENT_ROW_TYPES.WEBSITES,
    SEGMENT_ROW_TYPES.LIFESTYLE,
    SEGMENT_ROW_TYPES.ADVANCED_TV_SHOWS,
    SEGMENT_ROW_TYPES.SMART_TV_COMMERCIALS,
    SEGMENT_ROW_TYPES.FIRST_PARTY
];

const STREAMING_SEGMENT_TYPES = [
    SEGMENT_ROW_TYPES.SMART_TV_DEMOGRAPHICS,
    SEGMENT_ROW_TYPES.INTERESTS,
    SEGMENT_ROW_TYPES.WEBSITES,
    SEGMENT_ROW_TYPES.LIFESTYLE,
    SEGMENT_ROW_TYPES.ADVANCED_TV_SHOWS,
    SEGMENT_ROW_TYPES.FIRST_PARTY
];

const AU_TELCO_SEGMENT_TYPES = [
    SEGMENT_ROW_TYPES.DEMOGRAPHICS,
    SEGMENT_ROW_TYPES.INTERESTS,
    SEGMENT_ROW_TYPES.WEBSITES,
    debugOnlySegment(SEGMENT_ROW_TYPES.LIFESTYLE),
    debugOnlySegment(disabledSegment(SEGMENT_ROW_TYPES.APPS))
];

const LINKEDIN_SEGMENT_TYPES = [
    SEGMENT_ROW_TYPES.LINKEDIN_DEMOGRAPHICS,
    SEGMENT_ROW_TYPES.LINKEDIN_INDUSTRIES,
    SEGMENT_ROW_TYPES.LINKEDIN_COMPANIES,
    SEGMENT_ROW_TYPES.LINKEDIN_JOBS
];

const channelToSegmentTypes = {
    linkedin: LINKEDIN_SEGMENT_TYPES,
    data_spark: SG_TELCO_SEGMENT_TYPES,
    snbb: SG_TELCO_SEGMENT_TYPES,
    sg_bidstream: SG_TELCO_SEGMENT_TYPES,
    bidstream: SG_TELCO_SEGMENT_TYPES,
    au_telco: AU_TELCO_SEGMENT_TYPES,
    articles: WEB_SEGMENT_TYPES,
    smart_tv_inscape: SMART_TV_SEGMENT_TYPES,
    tivo: ADVANCED_TV_SEGMENT_TYPES,
    hisense: ADVANCED_TV_SEGMENT_TYPES,
    peerlogix: STREAMING_SEGMENT_TYPES,
};

function audienceBuilderController($scope, context, audienceMgmt, filtersPartition, interestsModal, websitesModal,
    loadAudienceModal, notificator, confirmAction, linkedinJobsModal, linkedinCompaniesModal, abiPermissions,
    $stateParams, $state, errorMgmt, $window, $rootScope, appsModal, dspService, ssoService, $location,
    audienceTableStructure, TargetsCommon, $timeout) {

    let current_channel;
    let linkedinMetaDataPromise;
    let originalAudienceName = '';
    const DEMOGRAPHICS_SEGMENT_TYPES_WITH_GEO_SELECTION = ['demographics', 'smartTvDemographics', 'country'];
    const DEMOGRAPHICS_SEGMENT_TYPES = DEMOGRAPHICS_SEGMENT_TYPES_WITH_GEO_SELECTION.concat(['linkedinDemographics']);
    const debugUser = $scope.$root.user.userType === 'debug';
    const userId = $scope.$root.user.id;
    const SEGMENT_MODAL_MAP = {
        interests: interestsModal, websites: websitesModal, linkedinJobs: linkedinJobsModal,
        linkedinCompanies: linkedinCompaniesModal, apps: appsModal
    };
    const CHANNEL_TO_LIFESTYLE_NAME_MAP = {
        articles: 'articlesBehavioralSegment',
        au_telco: 'auTelcoBehavioralSegment',
        data_spark: 'sgTelcoBehavioralSegment',
        smart_tv: 'inscapeBehavioralSegment'
    };
    const ALL_GEOS = {label: 'All Geos', value: 'all', id: -1};
    $scope.SEGMENT_ROW_MAP = SEGMENT_ROW_MAP;
    $scope.logicalOperands = LOGICAL_OPERANDS;
    validateChannelContext();
    validateFirstPartySegmentContext();
    loadFromContext();
    changeSegmentTypes(current_channel);
    disableEnableLinkedinSegments();

    MixpanelAudience.trackPageView('audience-builder', context.current.audience_app.current_channel.value);

    $scope.getSegmentIds = getSegmentIds;
    $scope.createAudienceTargetTaxonomy = createAudienceTargetTaxonomy;
    $scope.createAudienceTargetUserList = createAudienceTargetUserList;
    $scope.createAmplifiedAudience = createAmplifiedAudience;
    $scope.createAlwaysOnAudience = createAlwaysOnAudience;
    $scope.createDeterministicAudience = createDeterministicAudience;
    $scope.createUserListForDeterministicAudience = createUserListForDeterministicAudience;
    $scope.getDataContract = getDataContract;
    $scope.getTaxonomyCategory = getTaxonomyCategory;
    $scope.dspService = dspService;
    $scope.ssoService = ssoService;
    $scope.notificator = notificator;
    $scope.filtersPartition = filtersPartition;
    $scope.context = context;
    $scope.channelFilterSideMenuSelectedChannel = $scope.audienceChannelsFilter.find((channel) => channel.value === context.current.audience_app.current_channel.value);
    $scope.audience = $scope.audience || {};
    $scope.isCloneAudienceOpen = false;
    $scope.isTimeframeVisible = abiPermissions.hasPermission(PERMISSION.TVA_CUSTOM_TIMEFRAME);
    $scope.reactSegmentModals = {
        lifestyle: {
            isOpen: false,
            newTitle: "Add a lifestyle segment",
            editTitle: "Modify segment's lifestyle",
            lifestyles: getLifestyles((context.current.audience_app || {}).current_channel || {}, debugUser)
        },
        '1st party': {
            isOpen: false,
            newTitle: "Add a 1st party segment",
            editTitle: "Modify segment's 1st party"
        },
        'smartTvCommercials': {
            isOpen: false,
            newTitle: getNewCommercialsTitle(current_channel),
            editTitle: "Modify segment's TV commercial viewership"
        },
        tvShows: {
            isOpen: false,
            newTitle: getNewTvShowsTitle(current_channel),
            editTitle: "Modify segment's TV viewership"
        },
        linkedinDemographics: {
            isOpen: false,
            newTitle: "Add a demographic segment",
            editTitle: "Modify segment's demographics",
            onCancel: () => $scope.modalCancelHandler('linkedinDemographics'),
            linkedinMetaDataPromise: linkedinMetaDataPromise,
        },
        linkedinIndustries: {
            isOpen: false,
            newTitle: "Add a segment based on industries",
            editTitle: "Modify segment's industries",
            onCancel: () => $scope.modalCancelHandler('linkedinIndustries'),
            linkedinMetaDataPromise: linkedinMetaDataPromise,
        },
        country: {
            isOpen: false,
            newTitle: "Select a Country",
            editTitle: "Select a Country",
            allGeos: getAllGeos(),
            onCancel: () => $scope.modalCancelHandler('country'),
            type: 'country',
        },
    };
    $scope.reactSegmentModals.advancedTvShows = _.clone($scope.reactSegmentModals.tvShows);
    const hasUsGeoPermission = abiPermissions.hasPermission(PERMISSION.GEO_US);
    const hasSgTelcoEthnicityPermission = abiPermissions.hasPermission(PERMISSION.SG_TELCO_ETHNICITY);
    ['demographics', 'smartTvDemographics'].map((type) => {
        $scope.reactSegmentModals[type] = {
            isOpen: false,
            newTitle: "Add a demographic segment",
            editTitle: "Modify segment's demographics",
            allGeos: getAllGeos(),
            disableGeos: true,
            hasUsGeoPermission: hasUsGeoPermission && type === 'demographics',
            hasSgTelcoEthnicityPermission: hasSgTelcoEthnicityPermission && type === 'demographics',
            onCancel: () => $scope.modalCancelHandler(type),
            type,
        }
    });
    $scope.isActivateVisible = false;
    $scope.isActivateAudienceEnabled = false;
    $scope.isActivateAudienceVisible = false;
    $scope.isContextualUniquenessError = false;
    $scope.activateAudienceDisabledText = '';
    $scope.hasAmplificationModeSelector = common.hasAmplificationModeSelector(context, abiPermissions);
    $scope.hideAudiencePreview = false;
    $scope.showAudienceIpCount = false;

    setHideAudiencePreview(current_channel);
    setShowAudienceIpCount(current_channel);
    $scope.isActivateTargetEnabled = () => abiPermissions.hasPermission(PERMISSION.ACTIVE_TARGETS) &&
                                           current_channel !== CHANNELS.linkedin?.name && !isLimitedAudienceByCountry(current_channel);

    $scope.setContextualUniquenessError = (is_uniqueness_error) => {
        $scope.isContextualUniquenessError = is_uniqueness_error;
    }

    $scope.handleActivatingTarget = () => {
        const segment = $scope.audience.segment;
        const channel = current_channel;
        TargetsCommon.openAudienceContextualTarget({
            segment,
            channel,
            phrasesPromise: () => getSegmentInterestsData($scope.audience.segment, QUERY_NAMES.phrases, current_channel),
            query: getSegmentParams(segment, QUERY_NAMES[$scope.tab], channel),
            audience_name: context.current.audience_app[current_channel].audience_name,
            $scope,
            program_id: context.current.p_id
        });
    };

    $scope.modalCancelHandler = (type) => {
        $scope.segmentType = null;
        const updatedReactSegmentModals = _.cloneDeep($scope.reactSegmentModals);
        updatedReactSegmentModals[type].isOpen = false;
        updatedReactSegmentModals.input = {};
        $scope.reactSegmentModals = updatedReactSegmentModals;
        $scope.$digest();
    };

    $scope.modalSubmitHandler = (values) => {
        $scope.segmentType = null;
        const segmentType = _.get(values, 'type');
        if (!segmentType) return;
        const segmentIndex = values.index;
        const updatedAudienceSegment = _.cloneDeep($scope.audience.segment);
        const updatedReactSegmentModals = _.cloneDeep($scope.reactSegmentModals);

        if (_.isNumber(segmentIndex) && segmentIndex >= 0) {
            const isSegmentChanged = !_.isEqual(updatedAudienceSegment[segmentIndex], values);
            const advancedTvGeoChanged = isSegmentChanged &&
                                         segmentType === 'country' &&
                                         CHANNELS[current_channel]?.isGeoMandatory &&
                                         values.geo !== updatedAudienceSegment[segmentIndex].geo;
            const segmentKeyDifferences = _.difference(Object.keys(updatedAudienceSegment[segmentIndex]), Object.keys(values));
            const updatedSegment = {..._.omit(updatedAudienceSegment[segmentIndex], segmentKeyDifferences), ...values};
            updatedAudienceSegment.splice(segmentIndex, 1, updatedSegment);
            $scope.audience.segment = _.reject(updatedAudienceSegment, (row) => advancedTvGeoChanged && row.type !== 'country');
            if (advancedTvGeoChanged) {
                $timeout(() => {
                    context.current.audience_app.default_geo = values.geo[0];
                    setDisabledSegmentTypes(current_channel)
                    setHideAudiencePreview(current_channel);
                    handleSmartTvCommercialsPromise({value: current_channel});
                })
            }
            refreshContext(isSegmentChanged);
        } else {
            const operand = $scope.advancedSegmentation ? $scope.filter.logicOperand[0] : $scope.logicalOperand;
            const newSegment = {operand, ...values};
            updatedAudienceSegment.push(newSegment);
            $scope.audience.segment = updatedAudienceSegment;
            if (current_channel === CHANNELS.linkedin?.name) disableEnableLinkedinSegments(segmentType);
            refreshContext(true);
        }

        updatedReactSegmentModals[segmentType].isOpen = false;
        $scope.reactSegmentModals = updatedReactSegmentModals;
        setShowAudienceIpCount(current_channel);
        $scope.$digest();
    };

    function getLifestyles(currentChannel, debugUser) {
        const lifestyles = filtersPartition.behavioralSegment.filter((segment) => segment.debugOnly ? debugUser : true);
        const channelLifestylesName = CHANNEL_TO_LIFESTYLE_NAME_MAP[currentChannel.value];
        const channelLifestyles = channelLifestylesName ? filtersPartition[channelLifestylesName].filter((segment) => segment.debugOnly ? debugUser : true) : [];
        return _.orderBy([...channelLifestyles, ...lifestyles], 'label', 'asc');
    }

    function getNewTvShowsTitle(currentChannel) {
        return "Add a segment based on " + getTvType(currentChannel) + " viewership";
    }

    function getNewCommercialsTitle(currentChannel) {
        return "Add a segment based on " + getTvCommercialType(currentChannel) + " commercial viewership";
    }

    function getAllGeos() {
        const addValueToGeo = (geo) => ({...geo, value: geo["id"]});
        if (CHANNELS[current_channel]?.limitedGeos?.length > 0) {
            return _.filter($scope.Geos.geos, (geo) => CHANNELS[current_channel]?.limitedGeos?.includes(geo.cc)).map(addValueToGeo);
        }
        return [ALL_GEOS].concat($scope.Geos.geos.map(addValueToGeo));
    }

    function updateModalLifestyles(channel) {
        if (!_.has($scope.reactSegmentModals, 'lifestyle.lifestyles')) return;
        $scope.reactSegmentModals.lifestyle.lifestyles = getLifestyles(channel, debugUser);
    }

    function updateModalTvShows(channel) {
        const title = getNewTvShowsTitle(channel);
        $scope.reactSegmentModals.tvShows.newTitle = title;
        $scope.reactSegmentModals.advancedTvShows.newTitle = title;
    }

    function updateModalCommercials(channel) {
        $scope.reactSegmentModals.smartTvCommercials.newTitle = getNewCommercialsTitle(channel);
    }

    function validateChannelContext() {
        $scope.audienceChannelsFilter = _.filter($scope.$root.audienceChannelsFilter, channel => (debugUser || !channel.debugOnly));
        if (!context.current.audience_app || !_.includes(_.map($scope.audienceChannelsFilter, 'value'), (context.current.audience_app.current_channel || {}).value)
            || (context.current.audience_app.current_channel.permission != null && !abiPermissions.hasPermission(context.current.audience_app.current_channel.permission))) {
            context.current.audience_app = {current_channel: common.getAvailableChannelFromContext($scope.audienceChannelsFilter, abiPermissions)};
        }
    }

    function removeTvShowsForWebChannel () {
        if (current_channel === CHANNELS.articles?.name) {
            if ($scope.audience.segment && _.isArray($scope.audience.segment)) {
                $scope.audience.segment = $scope.audience.segment.filter(segment => segment.type !== 'tvShows');
            }
            if (context.current.audience_app.articles.audience_segment && _.isArray(context.current.audience_app.articles.audience_segment)) {
                context.current.audience_app.articles.audience_segment = context.current.audience_app.articles.audience_segment.filter(segment => segment.type !== 'tvShows')
            }
        }
    }

    function loadFromContext() {
        current_channel = context.current.audience_app.current_channel.value;
        if (!context.current.audience_app[current_channel]) context.current.audience_app[current_channel] = {};
        let channel_audience = context.current.audience_app[current_channel];
        if (channel_audience.audience_activation?.deterministic &&
            !channel_audience.audience_activation.deterministic.is_dynamic) {
            _.each(_.filter(channel_audience.audience_segment,
                            (s) => (TV_SHOWS_SEGMENT_TYPES + COMMERCIALS_SEGMENT_TYPES).includes(s.type)),
                   (s) => delete s.timeframe);
        }

        $scope.audience = {
            id: channel_audience.audience_id || '',
            name: channel_audience.audience_name || '',
            segment: getSegmentWithAppliedMandatoryFields(channel_audience.audience_segment || []),
            activation: channel_audience.audience_activation,
        };
        $scope.advancedSegmentation = channel_audience.audience_advancedSegmentation || false;
        $scope.isAudienceDeterministicActivated = channel_audience.is_audience_deterministic_activated || false;
        $scope.isAudienceAmplifiedActivated = channel_audience.is_audience_amplified_activated || false;
        $scope.isAudienceAlwaysOnActivated = channel_audience.is_audience_always_on_activated || false;
        $scope.activatedAmplifiedThreshold = channel_audience.activated_amplified_threshold || null;
        $scope.activatedAlwaysOnThreshold = channel_audience.activated_always_on_threshold || null;
        $scope.activatedMarket = channel_audience.activated_market;
        $scope.activatedAdvertiser = channel_audience.activated_advertiser;
        $scope.activatedDataContractId = channel_audience.activated_data_contract_id;
        $scope.activatedDataContractText = channel_audience.activated_data_contract_text;
        $scope.activatedCategoryId = channel_audience.activated_category_id;
        if (_.isEmpty(channel_audience.audience_logicalOperand)) {
            $scope.logicalOperand = $scope.logicalOperands[0];
        } else {
            $scope.logicalOperand = _.find($scope.logicalOperands, {value: channel_audience.audience_logicalOperand.value});
        }
        if (!$scope.advancedSegmentation) _.each($scope.audience.segment, (s) => s.operand = $scope.logicalOperand);
        if ($scope.reactSegmentModals) { //Only if this has been initialized
            DEMOGRAPHICS_SEGMENT_TYPES_WITH_GEO_SELECTION.forEach((segmentType) => {
                $scope.reactSegmentModals[segmentType].allGeos = getAllGeos();
            });
        }

        removeTvShowsForWebChannel();
    }

    $scope.onLogicalOperandChange = (selectedOperand) => {
        $scope.logicalOperand = selectedOperand;
        let newAudienceSegment = _.cloneDeep($scope.audience.segment);
        _.each(newAudienceSegment, (segment) => segment.operand = selectedOperand);
        $scope.audience.segment = newAudienceSegment;
        refreshContext(true);
    };

    $scope.onToggleAdvancedSegmentation = (isOn) => {
        MixpanelAudience.trackAdvancedSegmentation(context.current.audience_app.current_channel.value);
        if ($scope.isAudienceActivated()) return $scope.updateIsCloneAudienceOpen(true);
        if (!isOn) {
            const advancedOperands = _($scope.audience.segment).map('operand.value').uniq().value();
            if (advancedOperands.length === 1) {
                const operand = _.find($scope.logicalOperands, {value: advancedOperands[0] || 'and'});
                $scope.logicalOperand = operand ? operand : $scope.logicalOperand;
            }
            let newAudienceSegment = _.cloneDeep($scope.audience.segment);
            _.each(newAudienceSegment, (segment) => segment.operand = $scope.logicalOperand);
            const isSegmentChanged = !_.isEqual(newAudienceSegment, $scope.audience.segment);
            $scope.audience.segment = newAudienceSegment;
            refreshContext(isSegmentChanged);
        }
        $scope.advancedSegmentation = isOn;
    };

    $scope.loadAudience = () => {
        getConfirmation(true, () => {
            loadAudienceModal.showModal(current_channel, $scope.isActivateAudienceVisible).then((modal) => {
                modal.close.then((value) => {
                    // When the user uses browse back btn, there is no "segments" attribute.
                    // We use this property as a validation, i.e. any valid response should include a "value" and segments" attribute.
                    if (value && !_.isEmpty(value.segments)) {
                        MixpanelAudience.trackLoadAudience(value.name, context.current.audience_app.current_channel.value);
                        $scope.audience = {
                            id: value.id,
                            name: value.name,
                            segment: getSegmentWithAppliedMandatoryFields(value.segments),
                            activation: _.cloneDeep(value.activation),
                        };
                        originalAudienceName = value.name;
                        $scope.advancedSegmentation = value.advancedSegmentation;
                        $scope.isAudienceDeterministicActivated = !!value.activation?.deterministic;
                        $scope.isAudienceAmplifiedActivated = !!value.activation?.amplified;
                        $scope.isAudienceAlwaysOnActivated = !!value.activation?.always_on;
                        $scope.activatedAmplifiedThreshold = value.activation?.amplified?.threshold;
                        $scope.activatedAlwaysOnThreshold = value.activation?.always_on?.threshold;
                        $scope.activatedMarket = value.activation?.market;
                        $scope.activatedAdvertiser = value.activation?.advertiser;
                        $scope.activatedDataContractId = value.activation?.data_contract_id;
                        $scope.activatedDataContractText = value.activation?.data_contract_text;
                        $scope.activatedCategoryId = value.activation?.category_id;
                        $scope.logicalOperand = !$scope.advancedSegmentation && value.segments[0].operand ? value.segments[0].operand : $scope.logicalOperands[0];
                        notificator.success({body: `"${$scope.audience.name}" audience loaded successfully`});
                        clearNameErrors();
                        refreshContext(true);
                        removeTvShowsForWebChannel();
                    }

                    getAudiencesForCurrentChannel(true);
                    if (current_channel === CHANNELS.linkedin?.name) disableEnableLinkedinSegments();
                    setShowAudienceIpCount(current_channel);
                });
            });
        });
    };

    $scope.onChannelFilterChange = (channelObj) => {
        if ($scope.isContextualUniquenessError) $scope.setContextualUniquenessError(false);
        context.current.audience_app.current_channel = channelObj;
        setHideAudiencePreview(channelObj.value);
        setShowAudienceIpCount(channelObj.value);
    };

    function getConfirmation(isLoad, callback) {
        if (!$scope.audienceDirty() || $scope.isAudienceActivated()) {
            callback();
        } else {
            const confirm = 'Continue without saving';
            const cancel = 'Cancel';
            const title = 'Audience unsaved';
            const msg = `Are you sure you want to ${isLoad ? 'load' : 'create'} a new audience
                         without saving your changes to this audience?`;

            confirmAction.getConfirmation(msg, confirm, cancel, title, 'audience-confirmation', true).then((modal) => {
                modal.close.then((confirmation) => {
                    if (confirmation === true) {
                        callback();
                    }
                });
            });
        }
    }

    function assignModalsProps(type, disableGeo) {
        if (DEMOGRAPHICS_SEGMENT_TYPES_WITH_GEO_SELECTION.includes(type)) {
            $scope.reactSegmentModals[type].channel = context.current.audience_app.current_channel.value;
            $scope.reactSegmentModals[type].defaultGeo = CHANNELS[context.current.audience_app.current_channel.value]?.isGeoMandatory ?
                                                           context.current.audience_app.default_geo : '';
        }

        if (type === 'linkedinDemographics' || type === 'linkedinIndustries') {
            $scope.reactSegmentModals[type].linkedinMetaDataPromise = linkedinMetaDataPromise;
        }

        if (DEMOGRAPHICS_SEGMENT_TYPES.includes(type)) {
            $scope.reactSegmentModals[type].disableGeos = disableGeo;
        }
    }

    $scope.audienceDirty = () => {
        const current_audience = _.find(programAudiences, {id: $scope.audience.id}) || {segments: [], name: '', advancedSegmentation: false};
        return angular.toJson(current_audience.segments) !== angular.toJson($scope.audience.segment)
            || current_audience.name !== $scope.audience.name
            || current_audience.advancedSegmentation !== $scope.advancedSegmentation;
    };

    $scope.resetAudience = () => {
        getConfirmation(false, () => {
            MixpanelAudience.trackNewAudience({ channel: current_channel });
            $scope.audience = {name: '', segment: getSegmentWithAppliedMandatoryFields([])};
            originalAudienceName = '';
            $scope.advancedSegmentation = false;
            $scope.isAudienceDeterministicActivated = false;
            $scope.isAudienceAmplifiedActivated = false;
            $scope.isAudienceAlwaysOnActivated = false;
            $scope.activatedAmplifiedThreshold = null;
            $scope.activatedAlwaysOnThreshold = null;
            $scope.activatedMarket = null;
            $scope.activatedAdvertiser = null;
            $scope.activatedDataContractId = null;
            $scope.activatedDataContractText = null;
            $scope.activatedCategoryId = null;
            $scope.logicalOperand = $scope.logicalOperands[0];
            const audienceApp = context.current.audience_app[current_channel];
            audienceApp.audience_id = null;
            refreshContext(true);
            clearNameErrors();
            if (current_channel === CHANNELS.linkedin?.name) disableEnableLinkedinSegments();
            removeTvShowsForWebChannel();
            setShowAudienceIpCount(current_channel);
        });
    };

    $scope.deleteSegmentRow = (index) => {
        if ($scope.isSegmentRowWithoutDelete($scope.audience.segment[index])) return;
        if ($scope.isAudienceActivated()) return $scope.updateIsCloneAudienceOpen(true);
        let newAudienceSegment = _.cloneDeep($scope.audience.segment);
        let deletedSegmentRow = newAudienceSegment.splice(index, 1)[0];
        $scope.audience.segment = newAudienceSegment;
        disableEnableLinkedinSegments(deletedSegmentRow.type);
        refreshContext(true);
        setShowAudienceIpCount(current_channel);
    };

    $scope.editSegmentRow = (index) => {
        if ($scope.isAudienceActivated()) return $scope.updateIsCloneAudienceOpen(true);
        const type = $scope.audience.segment[index].type;
        $scope.segmentType = type;
        const modal = SEGMENT_MODAL_MAP[type];
        const disableGeo = DEMOGRAPHICS_SEGMENT_TYPES.includes(type) && _.filter($scope.audience.segment, (seg) => DEMOGRAPHICS_SEGMENT_TYPES.includes(seg.type)).length > 1;

        assignModalsProps(type, disableGeo);

        if (!modal) {
            $scope.reactSegmentModals[type].isOpen = true;
            $scope.reactSegmentModals[type].modalTitle = $scope.reactSegmentModals[type].editTitle;
            $scope.reactSegmentModals[type].input = {...$scope.audience.segment[index], index};
            return;
        }

        modal.showModal($scope.audience.segment[index], false, disableGeo, current_channel,
            {
                linkedinMetaDataPromise, abiPermissions, debugUser
            }).then((modal) => {
                modal.close.then((value) => {
                    $scope.segmentType = null;
                    // When the user uses browse back btn, there is no "type" attribute.
                    // We use this property as a validation, i.e. any valid response should include a "type" attribute.
                    if (value && value["type"]) {
                        let newAudienceSegment = _.cloneDeep($scope.audience.segment);
                        const isSegmentChanged = !_.isEqual(value, newAudienceSegment[index]);
                        newAudienceSegment[index] = value;
                        $scope.audience.segment = newAudienceSegment;
                        refreshContext(isSegmentChanged);
                    }
                });
            })
    };

    $scope.newSegmentRow = (type) => {
        if ($scope.isAudienceActivated()) return $scope.updateIsCloneAudienceOpen(true);
        $scope.segmentType = type;
        const modal = SEGMENT_MODAL_MAP[type];
        const prevDefinedDemographics = _.filter($scope.audience.segment, (seg) => DEMOGRAPHICS_SEGMENT_TYPES.includes(seg.type))[0] || {};
        const disableGeo = DEMOGRAPHICS_SEGMENT_TYPES.includes(type) && Object.keys(prevDefinedDemographics).length > 0;
        const initialDefinedProperties = disableGeo ? _.pick(prevDefinedDemographics, ['geo', 'states', 'ethnicity', 'income']) : null;

        assignModalsProps(type, disableGeo);

        MixpanelAudience.trackSegment(type, context.current.audience_app.current_channel.value);
        if (!modal) {
            $scope.reactSegmentModals[type].isOpen = true;
            $scope.reactSegmentModals[type].modalTitle = $scope.reactSegmentModals[type].newTitle;
            $scope.reactSegmentModals[type].input = ((type === 'demographics') && initialDefinedProperties) || {};
            return;
        }

        modal.showModal(initialDefinedProperties, true, disableGeo, current_channel,
            {
                linkedinMetaDataPromise, abiPermissions, debugUser
            }).then((modal) => {
                modal.close.then((value) => {
                    $scope.segmentType = null;
                    if (value?.type) {
                        value.operand = $scope.advancedSegmentation ? $scope.filter.logicOperand[0] : $scope.logicalOperand;
                        let newAudienceSegment = _.cloneDeep($scope.audience.segment);
                        newAudienceSegment.push(value);
                        $scope.audience.segment = newAudienceSegment;
                        setShowAudienceIpCount(current_channel);
                        refreshContext(true);
                        disableEnableLinkedinSegments(value["type"]);
                    }
                });
            });
    };

    async function isInvalidName() {
        const {audience: {id, name}} = $scope;
        $scope.name_missing = !name;
        if ($scope.name_missing) return true;

        $scope.name_exist_in_program = await isNameExists(id, name);
        return $scope.name_exist_in_program;
    }

    async function isNameExists(id, name) {
        const audiences = await getAudiencesForCurrentChannel();
        return !!audiences.find((a) => a.id !== id && a.name.toLowerCase() === name.toLowerCase());
    }

    async function isSensitiveName() {
        const {audience: {name}} = $scope;
        const {passed, text} = await checkNameSensitivity(name);
        $scope.name_is_insensitive = !passed;
        $scope.sensitiveTitleText = text;
        return $scope.name_is_insensitive;
    }

    function handleAudienceSensitivity(audience, props) {
        return new Promise(async (resolve, reject) => {
            try {
                const invalidName = await isSensitiveName();
                if (invalidName) return resolve({ canceled: true });

                if (!$scope.isActivateAudienceEnabled) return resolve({canceled: false, isDirty: false});

                const {
                    reviewSensitivityHandler,
                    sensitivityData,
                } = await checkSensitivity(audience, (newSegments) => {
                    if (newSegments) audience.segments = newSegments;

                    $scope.reviewSensitivity = { isOpen: false };
                    $scope.$apply();
                    resolve({ isDirty: !_.isEmpty(newSegments) });
                });

                if (_.isEmpty(sensitivityData)) return resolve({ isDirty: false });

                const cancelHandler = () => {
                    $scope.reviewSensitivity = { isOpen: false };
                    $scope.$apply();
                    resolve({ canceled: true });
                };
                $scope.reviewSensitivity = {
                    isOpen: true,
                    data: sensitivityData,
                    onCancel: cancelHandler,
                    onSubmit: reviewSensitivityHandler,
                    ...props,
                };
                $scope.$apply();
            } catch (e) {
                reject(e);
            }
        });
    }

    let saveInProcess = false;
    async function saveAudience(props) {
        if (saveInProcess) return { canceled: true };

        saveInProcess = true;
        try {
            const invalidName = await isInvalidName();
            if (invalidName) return { canceled: true };

            const audience = {
                name: $scope.audience.name,
                segments: _.cloneDeep($scope.audience.segment), // not sure why the audience has "segment" and not "segments"
                advancedSegmentation: $scope.advancedSegmentation,
                channel: current_channel,
            };
            const {canceled, isDirty} = [CHANNELS.smart_tv_inscape?.name, CHANNELS.tivo?.name, CHANNELS.articles?.name].includes(current_channel)
             ? await handleAudienceSensitivity(audience, props)
             : {canceled: false, isDirty: false};
            if (canceled) return { canceled: true };

            // skip saving of audience if there are no segments to save (because removing the sensitive ones cleared everything)
            $scope.audience.segment = audience.segments;
            if (_.isEmpty(audience.segments)) return { canceled: true };

            // skip saving of audience if it is not changed
            if (!isDirty && !$scope.audienceDirty()) return { canceled: false };

            const res = await (
              $scope.audience.id ? audienceMgmt.update($scope.audience.id, {data: audience}) : audienceMgmt.create({data: audience})
            );
            const rename = originalAudienceName && res.name !== originalAudienceName;
            const msg = rename ? `The audience "${originalAudienceName}" was renamed to "${audience.name}" and saved successfully`
                            : `The audience "${audience.name}" was saved successfully`;
            notificator.success({body: msg});
            await finalizeSave(res);
            return { canceled: false };
        } catch (e) {
            const msg = 'Failed saving audience';
            console.error(msg, e);
            notificator.error({ body: msg });
            return { canceled: true };
        } finally {
            saveInProcess = false;
        }
    }

    function trackSaveAudience(segments) {
        const inputSegments = segments.map(
          ({required = [], included = []}) => [
              ...required.map(sr => sr.text),
              ...included.map(si => si.text),
          ]
        );
        const trackProps = {
            channel: current_channel,
            program: context.program.name,
            segment: (context.current.audience_app || {})[current_channel],
            "input segments": inputSegments
        };
        MixpanelAudience.trackSaveAudience(trackProps);
    }

    function finalizeSave(audience) {
        $scope.audience.id = audience.id;
        $scope.audience.name = audience.name;
        trackSaveAudience(audience.segments);
        refreshContext(false);
        clearNameErrors();
        return getAudiencesForCurrentChannel(true);
    }

    $scope.saveAudience = () => {
        return saveAudience({
            modalTitle: 'Review before saving',
            keepPhrasesText: 'Save Audience',
            removePhrasesText: 'Remove & Save',
        });
    };

    async function checkDemographicsData(segment, options) {
        const size = await getAudienceSize(segment, options);
        if (isAudienceSizeTooSmall(size)) {
            notificator.error('Your audience is too narrow and cannot be activated. Please refine your audience.');
            return false;
        }
        return true;
    }

    async function checkName(id, name) {
        if (!name) return { isValid: false };

        const nameExists = await isNameExists(id, name);
        if (nameExists) return {
            error: `The name "${name}" already exists. Please choose a different name.`,
            isValid: false,
        };

        const {passed, text} = await checkNameSensitivity(name);
        if (!passed) return { error: text, isValid: false };

        return { isValid: true };
    }

    function getAudienceName(id, origName) {
        return new Promise(async (resolve) => {
            const { isValid, error } = await checkName(id, origName);
            if (isValid) {
                return resolve({ name: origName });
            }

            $scope.audienceNameProps = {
                isOpen: true,
                origName,
                origErrorMessage: error,
                checkName: (name) => checkName(id, name),
                onCancel() {
                    $scope.audienceNameProps = { isOpen: false };
                    $scope.$apply();
                    resolve({ canceled: true });
                },
                onSubmit(name) {
                    $scope.audienceNameProps = { isOpen: false };
                    $scope.$apply();
                    resolve({ name });
                },
            };
        });
    }

    $scope.handleActivatingAudience = async () => {
        const {audience: {id, name: origName}} = $scope;
        const {name, canceled: nameCanceled} = await getAudienceName(id, origName);
        if (nameCanceled) return { canceled: true };

        $scope.audience.name = name;
        const { canceled: saveCanceled } = await saveAudience({
            modalTitle: 'Review before activating',
            keepPhrasesText: 'Activate Audience',
            removePhrasesText: 'Remove & Activate',
        });
        if (saveCanceled) return { canceled: true };

        const isBidstream = current_channel === CHANNELS.articles?.name;
        const demographicsDataOk = await checkDemographicsData($scope.audience.segment, {
            channel: current_channel,
            userId,
            isBidstream,
            minimalSize: 500,
            filterBidstreamDomains: isBidstream,
        });
        if (!demographicsDataOk) return { canceled: true };

        return {
            audienceId: $scope.audience.id,
            audienceName: $scope.audience.name,
        };
    };

    $scope.clearNameErrors = clearNameErrors;

    function clearNameErrors() {
        $scope.name_exist_in_program = false;
        $scope.name_missing = false;
        $scope.name_is_insensitive = false;
        $scope.sensitiveTitleText = '';
        $scope.cloned_audience_name_auto_generated = false;
    }

    let programAudiences = [];

    function getAudiencesForCurrentChannel(force = false) {
        const audienceApp = context.current.audience_app[current_channel];

        // the best way to check if a value is a promise
        // https://stackoverflow.com/questions/27746304/how-do-i-tell-if-an-object-is-a-promise/38339199#38339199
        if (force || Promise.resolve(audienceApp.getProgramAudiencePromise) !== audienceApp.getProgramAudiencePromise) {
            audienceApp.getProgramAudiencePromise = getProgramAudience(current_channel);
        }

        return audienceApp.getProgramAudiencePromise;
    }

    async function getProgramAudience(channel) {
        const audienceApp = context.current.audience_app[channel];
        programAudiences = await audienceMgmt.list();
        const currentAudience = _.find(programAudiences, {name: $scope.audience.name}) || {};
        $scope.audience.id = currentAudience.id;
        originalAudienceName = currentAudience.name;
        $scope.disableLoadAudience = programAudiences.length === 0;
        // map audience ids to names, for later display
        audienceApp.audience_ids = programAudiences.reduce((obj, aud) => {
            obj[aud.id] = aud.name;
            return obj;
        }, {});
        audienceApp.audience_id = currentAudience.id;
        return programAudiences;
    }

    async function updateAudienceAppForChannel(channel, audience, isSegmentChanged) {
        context.current.audience_app[channel] = {
            ...context.current.audience_app[channel],
            audience_targetDisabled: !$scope.hasAudienceData,
            is_activate_target_enabled: $scope.isActivateTargetEnabled(),
            is_audience_deterministic_activated: $scope.isAudienceDeterministicActivated,
            is_audience_amplified_activated: $scope.isAudienceAmplifiedActivated,
            is_audience_always_on_activated: $scope.isAudienceAlwaysOnActivated,
            activated_amplified_threshold: $scope.activatedAmplifiedThreshold,
            activated_always_on_threshold: $scope.activatedAlwaysOnThreshold,
            activated_market: $scope.activatedMarket,
            activated_advertiser: $scope.activatedAdvertiser,
            activated_data_contract_id: $scope.activatedDataContractId,
            activated_data_contract_text: $scope.activatedDataContractText,
            activated_category_id: $scope.activatedCategoryId,
            is_audience_dirty: $scope.audienceDirty(),
            finalizeSave: finalizeSave,
        };
        $scope.isActivateAudienceEnabled = false;
        $scope.activateAudienceDisabledText = '';
        const {isEnabled, isVisible, disabledText} = await isActivateAudienceEnabled(abiPermissions, channel, audience, userId);
        $scope.isActivateAudienceEnabled = isEnabled;
        $scope.isActivateAudienceVisible = isVisible;
        $scope.activateAudienceDisabledText = disabledText;
        $scope.isActivateVisible = isVisible || $scope.isActivateTargetEnabled();
        if ($scope.isContextualUniquenessError) $scope.setContextualUniquenessError(!isSegmentChanged)
        $scope.$digest();
    }

    getAudiencesForCurrentChannel(true);
    const unbindContextListener = context.onChange((newVal, oldVal) => {
        if (oldVal && newVal.p_id === oldVal.p_id) return;
        clearNameErrors();
        loadFromContext();
        getAudiencesForCurrentChannel();
    });

    $scope.$on('$destroy', unbindContextListener);
    $scope.refreshContext = refreshContext;

    async function refreshContext(isSegmentChanged, channel = current_channel) {
        const channelAudience = context.current.audience_app[channel];
        channelAudience.audience_segment = _.cloneDeep($scope.audience.segment);
        channelAudience.audience_activation = _.cloneDeep($scope.audience.activation);
        channelAudience.audience_name = $scope.audience.name;
        channelAudience.audience_id = $scope.audience.id;
        channelAudience.audience_advancedSegmentation = $scope.advancedSegmentation;
        channelAudience.audience_logicalOperand = _.cloneDeep($scope.logicalOperand);
        delete channelAudience.audience_interestsToTest;
        await updateAudienceAppForChannel(channel, $scope.audience, isSegmentChanged);
    }

    const nameInput = angular.element(document.querySelector('.segment-name'));
    const inputFont = nameInput.css('font');

    $scope.rename = (onBlur) => {
        if (saveInProcess) return;
        if (!onBlur) document.querySelector('.segment-name').blur();
        if ($scope.audience.name === originalAudienceName || $scope.audience.segment.length === 0) return;
        $scope.saveAudience();
    };

    $scope.calcAsteriskLeft = () => 38 + common.getTextWidth($scope.audience.name, inputFont) * 1.4;

    function getTooltipOffsets(index, textLength) {
        let elemWidth = $(".audience-builder").width();
        let limit = ($rootScope.filterMenuOpen ? 800 : 1035);
        let offset = _.max([(elemWidth - limit), 0]);
        return {x: (offset / 10) * index - textLength, y: elemWidth > limit + 200 ? -20 : -50};
    }

    $scope.getSegmentValuesSummary = (segment) => getSegmentValuesSummary(segment, filtersPartition);

    $scope.onLogicOperandChange = (segment, value, index) => {
        if ($scope.isAudienceActivated()) return $scope.updateIsCloneAudienceOpen(true);
        const clonedAudienceSegment = _.cloneDeep($scope.audience.segment);
        // Because the operand is changed mutably by the am-input, the prevProps and currentProps are the same, thus
        // widget update is not triggered
        clonedAudienceSegment[index].immutableOperand = value;
        $scope.audience.segment = clonedAudienceSegment;
        refreshContext(true);
    };

    // When you switch to a new program on audience_builder page.
    $scope.$watch('context.program', () => {
        if (context.current.audience_app) return;
        context.current.audience_app = {current_channel: $scope.audienceChannelsFilter.find(channel => channel.value === current_channel)};
        loadFromContext();
        getAudiencesForCurrentChannel();
    });

    $scope.$watch('context.current.audience_app.current_channel', channelChanged);
    $scope.$watch('context.current.program', channelChanged);
    $($window).on("resize", recreateTooltips);
    let isRedirected = false;

    function channelChanged(newChannel = {}, oldChannel = {}) {
        if (_.isEmpty(newChannel) && _.isEmpty(oldChannel)) return;
        setDisabledFirstPartyModalValue(true, newChannel.value);
        $scope.channelAuthorized = false;
        const channelParam = $stateParams.channel || $location.search().channel;

        if (channelParam) {
            $stateParams.channel = null;
            if ($location.search().hasOwnProperty('channel')) $location.search('channel', null);
            isRedirected = true;
            context.current.audience_app[current_channel].getProgramAudiencePromise = undefined;
            return validateChannelFromFilters(channelParam);
        }

        $scope.hasAmplificationModeSelector = common.hasAmplificationModeSelector(context, abiPermissions);
        $scope.channelFilterSideMenuSelectedChannel = $scope.audienceChannelsFilter.find((channel) => channel.value === context.current.audience_app.current_channel.value);
        channelAuthorizationCheck(newChannel).then((authorizationStatus) => {
            if (!authorizationStatus) return;
            if (!authorizationStatus.authorized) return handleChannelNotAuthorized(newChannel, oldChannel, authorizationStatus.error);
            $scope.channelAuthorized = true;
            if (newChannel.value === CHANNELS.articles?.name) handleArticlesChannel(newChannel);
            if (isSmartTvChannel(newChannel.value) && !isAdvancedTvChannel(newChannel.value)) handleSmartTvChannel(newChannel);
            if (isAdvancedTvChannel(newChannel.value)) handleAdvancedTvChannel(newChannel);
            if (newChannel.value === CHANNELS.linkedin?.name) linkedinMetaDataPromise = getLinkedinMetaData(context.current.u_id);
            if (newChannel.value === oldChannel.value) return;
            changeSegmentTypes(newChannel.value);
            updateModalLifestyles(newChannel);
            updateModalTvShows((newChannel || {}).value);
            updateModalCommercials((newChannel || {}).value);
            if (isRedirected || $rootScope.programChanged) {
                isRedirected = false;
                $rootScope.programChanged = false;
            } else {
                refreshContext(true, oldChannel.value);
            }
            loadFromContext();
            getAudiencesForCurrentChannel();
            setShowAudienceIpCount(current_channel);
        })
    }

    function setDisabledFirstPartyModalValue(value, channel) {
        $scope.noFirstPartySegments = value;
        setDisabledSegmentTypes(channel);
    }

    function removeNoSegmentsTooltips() {
        $(".qtip.no-segments").remove();
    }

    function channelAuthorizationCheck(channel) {
        let tokenStatus = $stateParams.tokenStatus;
        $stateParams.tokenStatus = null;
        if (channel.value === CHANNELS.linkedin?.name) return validateUserAuthentication(context.current.u_id, tokenStatus, errorMgmt);
        return Promise.resolve({authorized: true});
    }

    function handleChannelNotAuthorized(newChannel, oldChannel, error) {
        console.info(`could not switch to ${newChannel.value} channel: ${error}`);
        context.current.audience_app.current_channel = $scope.audienceChannelsFilter.find(channel => channel.value === CHANNELS.articles?.name);
        $scope.channelFilterSideMenuSelectedChannel = context.current.audience_app.current_channel;
    }

    function disableEnableLinkedinSegments(type) {
        if (current_channel === CHANNELS.linkedin?.name && type) {
            let relevantSegmentType = LINKEDIN_SEGMENT_TYPES.find(segmentType => segmentType.value === type);
            Object.assign(relevantSegmentType, {isDisabled: !relevantSegmentType.isDisabled});
        } else {
            let segments = ((context.current.audience_app.linkedin || []).audience_segment || []).map(segment => segment.type);
            LINKEDIN_SEGMENT_TYPES.forEach((linkedinSegmentType) => {
                Object.assign(linkedinSegmentType, {isDisabled: segments.includes(linkedinSegmentType.value)});
            });
        }
        changeSegmentTypes(current_channel);
    }
    
    function isLimitedAudienceByCountry(channel) {
        return (channel === CHANNELS.hisense?.name && [COUNTRIES.AU?.cc, COUNTRIES.CA?.cc].includes(context?.current?.audience_app?.default_geo?.cc));
    }

    function setHideAudiencePreview(channel) {
        $scope.hideAudiencePreview = isLimitedAudienceByCountry(channel);
    }
    
    function setShowAudienceIpCount(channel) {
        $scope.showAudienceIpCount = isSmartTvChannel(channel) &&
          abiPermissions.hasPermission(showAudienceIpCountPermissionName) &&
          $scope.allowDeterministicSegment && ($scope.audience.segment.length > 0 );
    }
    
    function isDisabledSegmentTypeByChannelAndCountry(channel, segmentTypeValue) {
        return isLimitedAudienceByCountry(channel) && [SEGMENT_ROW_TYPES.SMART_TV_DEMOGRAPHICS.value, SEGMENT_ROW_TYPES.INTERESTS.value, SEGMENT_ROW_TYPES.WEBSITES.value, SEGMENT_ROW_TYPES.LIFESTYLE.value, SEGMENT_ROW_TYPES.FIRST_PARTY.value].includes(segmentTypeValue);
    }
    
    function isDisabledSegmentTypeByPermission(segmentTypeValue) {
        return !!(abiPermissions.hasPermission(PERMISSION.SMART_TVA_LITE) && [SEGMENT_ROW_TYPES.INTERESTS.value, SEGMENT_ROW_TYPES.WEBSITES.value].includes(segmentTypeValue));
    }

    function setDisabledSegmentTypes(channel) {
        const defaultTooltipMsg = 'Contact your Nexxen Account Manager to learn more about advanced insights-to-activation.';
        $scope.segmentTypes = $scope.segmentTypes.map((segment) => {
            const isDisabledByChannelAndCountry = isDisabledSegmentTypeByChannelAndCountry(channel, segment.value);
            if (segment.value === SEGMENT_ROW_TYPES.FIRST_PARTY.value) {
                return $scope.noFirstPartySegments || isDisabledByChannelAndCountry ? {
                        ...segment,
                        isDisabled: true,
                        tooltip: isLimitedAudienceByCountry(channel) ? defaultTooltipMsg : 'No segments applied for this program'
                    }
                    : _.omit(segment, ['isDisabled', 'tooltip']);
            }
            if (isSmartTvChannel(channel)) {
                const isDisabled = isDisabledSegmentTypeByPermission(segment.value) || isDisabledByChannelAndCountry;
                return {
                    ...segment,
                    isDisabled,
                    tooltip: isDisabled && defaultTooltipMsg,
                };
            }

            return segment;
        });
        recreateTooltips();
    }

    function recreateTooltips() {
        removeNoSegmentsTooltips();
        _.each($scope.segmentTypes, (segment, i) => {
            if (segment.tooltip) {
                let offsets = getTooltipOffsets(i, segment.tooltip.length);
                let element = $("toggle-button." + _.snakeCase(segment.value));
                let options = {
                    prerender: true,
                    position: {
                        at: 'center',
                        adjust: offsets
                    },
                    style: {classes: "no-segments common-tooltip-info"}
                };
                add_tooltip(element, 'info', options);
            }
        });
    }

    function debugOnlySegmentsOmitKeys(segments, debugUser) {
        return _.map(segments, (segment) => (
            debugUser && segment.debugOnly ? _.omit(segment, ['isDisabled', 'tagLabel']) : segment
        ));
    }

    function changeSegmentTypes(channel) {
        $scope.segmentTypes = channelToSegmentTypes[channel];
        $scope.segmentTypes = $scope.segmentTypes.filter((segmentType) =>
            segmentType.debugOnly ? (debugUser || segmentType.tagLabel) :
                (!segmentType.permission || abiPermissions.hasPermission(segmentType.permission))
        );
        $scope.segmentTypes = debugOnlySegmentsOmitKeys($scope.segmentTypes, debugUser);
        setDisabledSegmentTypes(channel);
    }

    function validateFirstPartySegmentContext() {
        if (abiPermissions.hasPermission(PERMISSION.FIRST_PARTY_SEGMENT) || !_.has(context.current.audience_app, 'articles.audience_segment')) return;
        context.current.audience_app.articles.audience_segment = _.reject(context.current.audience_app.articles.audience_segment, ['type', '1st party']);
    }

    function handleFirstPartySegments (newChannel) {
        if (abiPermissions.hasPermission(PERMISSION.FIRST_PARTY_SEGMENT)) {
            $scope.reactSegmentModals['1st party'].firstPartyPromise =
                getFirstPartyDataByProgram(
                    context.program, (data) => setDisabledFirstPartyModalValue(_.isEmpty(data), newChannel.value));
        }
    }

    function handleArticlesChannel (newChannel) {
        handleFirstPartySegments(newChannel);
        startTvMetadataPromises(newChannel);
    }

    function handleSmartTvChannel (newChannel) {
        handleSmartTvCommercialsPromise(newChannel);
        handleFirstPartySegments(newChannel);
        startTvMetadataPromises(newChannel);
    }

    function handleAdvancedTvChannel (newChannel) {
        handleSmartTvCommercialsPromise(newChannel);
        handleFirstPartySegments(newChannel);
        $scope.reactSegmentModals['advancedTvShows'].tvGenresMetadataPromise = getAdvancedTvGenresMetaData();
        if ($scope.hasActivateAudiencePermission(newChannel.value)) $scope.marketsAndAdvertisersPromise = dspService.getAmplificationMarketContext('value');
    }

    function handleSmartTvCommercialsPromise (newChannel) {
        let commercialsParams = [newChannel.value];
        if (CHANNELS[newChannel.value]?.isGeoMandatory) {
            context.current.audience_app.default_geo = context.current.audience_app.default_geo || DEFAULT_MANDATORY_GEO;
            const emptyBrandParents = [], emptyBrands = [], emptyProducts = [];
            const defaultGeoParam = context.current.audience_app.default_geo.cc.toLowerCase();
            commercialsParams.push(emptyBrandParents, emptyBrands, emptyProducts, defaultGeoParam);
        }
        $scope.reactSegmentModals.smartTvCommercials.commercialsMetadataPromise = getTvCommercialsMetaDataV2(...commercialsParams);
    }

    function startTvMetadataPromises (newChannel) {
        $scope.reactSegmentModals['tvShows'].tvNetworksMetadataPromise = getTvNetworksMetaData(newChannel.value);
        $scope.reactSegmentModals['tvShows'].tvGenresMetadataPromise = getTvGenresMetaData();
        $scope.reactSegmentModals['tvShows'].tvShowsMetadataPromise = getTvShowsMetaData(newChannel.value);
        if ($scope.hasActivateAudiencePermission(newChannel.value)) $scope.marketsAndAdvertisersPromise = dspService.getAmplificationMarketContext('value');
    }

    function getTvType(channel) {
        const TV_TYPES_BY_CHANNEL = {
            smart_tv_inscape: 'Smart TV',
            tivo: 'TV'
        };
        return TV_TYPES_BY_CHANNEL[channel] || 'online TV';
    }

    function getTvCommercialType(channel) {
        return channel === CHANNELS.smart_tv_inscape?.name ? "Smart TV Inscape" : 'TV';
    }

    function validateChannelFromFilters(nextChannel) {
        if (!nextChannel) return;
        const channelFromFilter = $scope.audienceChannelsFilter.find(channel => channel.value === nextChannel);
        if (!channelFromFilter) return redirectToTVAUpsellPage();
        if (channelFromFilter && nextChannel) {
            context.current.audience_app.current_channel = channelFromFilter;
            $scope.channelFilterSideMenuSelectedChannel = channelFromFilter;
        }
        return $scope.channelAuthorized = true;
    }

    function redirectToTVAUpsellPage() {
        sessionStorage['login_message'] = 'To gain access to TVA audience activation,</br>contact your account manager today.</br></br></br>';
        return $window.location.replace('/login_message');
    }

    $scope.hasActivateAudiencePermission = (channel) => hasActivateAudiencePermission(abiPermissions, channel);

    $scope.updateAudienceAmplifiedActivated = (activatedMarket, activatedAdvertiser, activatedAmplifiedThreshold) => {
        common.updateAudienceAmplifiedActivated(activatedMarket, activatedAdvertiser, activatedAmplifiedThreshold,
                                                context, $scope, current_channel);
        $scope.audience.activation = context.current.audience_app[current_channel].audience_activation;
        updateAudienceAppForChannel(current_channel, $scope.audience, false);
    };

    $scope.updateAudienceAlwaysOnActivated = (activatedDataContractId, activatedDataContractText, activatedCategoryId,
                                                       activatedAlwaysOnThreshold) => {
        $timeout(() => {
            common.updateAudienceAlwaysOnActivated(activatedDataContractId, activatedDataContractText, activatedCategoryId,
                                                   activatedAlwaysOnThreshold, context, $scope, current_channel);
            $scope.audience.activation = context.current.audience_app[current_channel].audience_activation;
            updateAudienceAppForChannel(current_channel, $scope.audience, false);
        });
    };

    $scope.updateAudienceDeterministicActivated = (activatedMarket, activatedAdvertiser) => {
        common.updateAudienceDeterministicActivated(activatedMarket, activatedAdvertiser, context, $scope, current_channel);
        $scope.audience.activation = context.current.audience_app[current_channel].audience_activation;
        updateAudienceAppForChannel(current_channel, $scope.audience, false);
    };

    $scope.isAudienceActivated = () => ($scope.isAudienceDeterministicActivated || $scope.isAudienceAmplifiedActivated);

    $scope.updateIsCloneAudienceOpen = (isOpen) => $scope.isCloneAudienceOpen = isOpen;

    $scope.onLogicOperandSummaryClick = () => $scope.updateIsCloneAudienceOpen(true);

    $scope.createClonedAudience = () => {
        $scope.audience = {...$scope.audience, name: `${$scope.audience.name}_copy`, activation: undefined};
        delete $scope.audience.id;
        originalAudienceName = '';
        $scope.isAudienceDeterministicActivated = false;
        $scope.isAudienceAmplifiedActivated = false;
        $scope.isAudienceAlwaysOnActivated = false;
        $scope.activatedAmplifiedThreshold = null;
        $scope.activatedAlwaysOnThreshold = null;
        $scope.activatedMarket = null;
        $scope.activatedAdvertiser = null;
        $scope.activatedDataContractId = null;
        $scope.activatedDataContractText = null;
        $scope.activatedCategoryId = null;
        refreshContext(false);
        $scope.cloned_audience_name_auto_generated = true;
        $scope.updateIsCloneAudienceOpen(false);
    };

    $scope.getAmplifiedEstimatedReachGoal = getAmplifiedEstimatedReachGoal;

    $scope.updateHasAudienceData = async (hasData) => {
        $scope.hasAudienceData = hasData;
        await updateAudienceAppForChannel(current_channel, $scope.audience, false);
    };

    $scope.hideChannelDropdown = () => {
        const name = $state.current.name;
        if (!name.startsWith('audience-')) return false;
        if (name !== 'audience-builder') return true;

        return !abiPermissions.hasPermission(PERMISSION.AUDIENCE_AU_TELCO_CHANNEL) &&
            !abiPermissions.hasPermission(PERMISSION.AUDIENCE_LINKEDIN_CHANNEL) &&
            !abiPermissions.hasPermission(PERMISSION.AUDIENCE_SG_TELCO_CHANNEL);
    };

    $scope.isSegmentWithDate = (index) => {
        const segment = $scope.audience.segment[index];
        return segment && segment.hasOwnProperty('startDate') && segment.hasOwnProperty('endDate');
    };

    $scope.isDeterministicDynamicAllowed = () => isDeterministicDynamicActivationAllowed($scope.audience.segment);
    $scope.allowDeterministicSegment = abiPermissions.hasPermission(deterministicPermissionName);
    $scope.allowCustomPriceSegment = abiPermissions.hasPermission(customPricePermissionName) &&
        (context.current.audience_app.current_channel.value !== CHANNELS.articles?.name);
    $scope.allowOptimizeSegment = abiPermissions.hasPermission(optimizeSegmentPermissionName);

    setShowAudienceIpCount(current_channel);
    function getSegmentWithAppliedMandatoryFields(segment) {
        if (!CHANNELS[current_channel]?.isGeoMandatory) return _.reject(segment, {type: "country"});
        let resultSegment = _.cloneDeep(segment);
        context.current.audience_app.default_geo = context.current.audience_app.default_geo || DEFAULT_MANDATORY_GEO;
        let countryRow = _.find(resultSegment, {type: "country"});
        if (!countryRow) {
            resultSegment.push({type: "country", geo: [context.current.audience_app.default_geo]});
        }
        _.each(resultSegment, (row) => {
            if (row.type === 'demographics') {
                delete row.geo;
            }
        });
        _.remove(resultSegment, (row) => row.type === 'demographics' &&
                                         _.isEmpty(_.omit(row, ['index', 'operand', 'type', 'noDelete'])));
        return resultSegment;
    }

    $scope.isSegmentRowWithoutDelete = ({type}) => type === 'country';
}

audienceBuilderModule.filter("trusted_html", ['$sce', function ($sce) {
    return function (htmlCode) {
        return $sce.trustAsHtml(htmlCode);
    }
}]);

export default audienceBuilderModule;
