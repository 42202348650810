import packageJson from '../../../package.json';
const ALLOWED_URL_PARTS = ["intelligence.amobee.com"];
const NOT_ALLOWED_URL_PARTS = ["proxy-tower", "lsap.intelligence.amobee.com"];
const isUrlAllowed = (url) => ALLOWED_URL_PARTS.some(allowedUrl => url.includes(allowedUrl)) &&
    !NOT_ALLOWED_URL_PARTS.some(notAllowedUrl => url.includes(notAllowedUrl));
const config = {
    applicationId: 'd70af27c-c05a-4634-b88a-953c7d1c9ccc', // TODO: need to use config-server and config file and not hard-coded like this
    clientToken: 'pubb7163cee982721ad61830d43fa9d716a', // TODO: need to use config-server and config file and not hard-coded like this
    site: 'datadoghq.com',
    service: 'discovery',
    env: new URL(window.location).hostname,
    version: packageJson.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackViewsManually: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [isUrlAllowed],
};
export default config;
